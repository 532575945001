import React, { Component, Fragment } from "react";
import { DraggableModal } from "/bbui/components/";
import { Form, Input, InputNumber, Select, Button, message } from "antd";
import Axios from "axios";
import AxiosFormData from "/helpers/AxiosFormData";
import { injectIntl, FormattedMessage } from "react-intl";
import { portType, proto_id, protoID, protoIP, icmpV4, icmpV6, ajaxUrl } from "/bbui/config/constants";
import { product } from "/config/constants";
import { hasEmojis } from "/bbui/helpers/utils";
import "../Objects.css"

const package_version = 'app';

// eslint-disable-next-line
const protoID8 = protoIP && protoIP[8] && protoIP[8].name ? protoIP[8].name : (protoIP && protoIP.protoIP && protoIP.protoIP[8] && protoIP.protoIP[8].name) ? protoIP.protoIP[8].name  : 'tcp';

class ServicesModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lockForm: false,
      message: null,
      // setando como default Protocol Type como "tcp" e Port Type como "Source/Destination"
      type: protoID8,
      itemMove: {
        "obj_services-port_dst_end": "",
        "obj_services-port_dst_ini": "",
        "obj_services-port_src_end": "",
        "obj_services-port_src_ini": "",
        "obj_services-port_type": portType[0].id,
        "obj_services-proto_id": proto_id['tcp'],
        "obj_services-proto_key": "",
        "obj_services-proto_type": protoID8,
        "obj_services-proto_value": proto_id['tcp'],
      },
      services: props.services,
      obj_service_list_id: 'obj_service-list-' + Date.now(),
      editTitlePrefix: '',
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.id !== prevProps.id) {
      const { id } = this.props;
      if (id) {
        this.setState({ editTitlePrefix: this.props.intl.formatMessage({ id: "common.edit" }) })
        this.setState({ lockForm: true })
        Axios.post(ajaxUrl[package_version].objects, AxiosFormData({ act: "getObjects", type: "services", id })).then((res) => {
          if (res.data) {
            let data = JSON.parse(res.data[0].data)
            this.setState({
              services: {
                lockForm: false,
                id,
                name: data['obj_services-name'],
                description: data['obj_services-description'],
                list: data['obj_services-ports'],
                gsmid: data['obj_services-gsmid']
              }
            }, () => {
              this.setState({ lockForm: false })
            })
          }
        })
      }
    }

    if (this.props.services !== prevProps.services) {
      this.setState({
        lockForm: false,
        services: this.props.services,
      })

      // if (this.props.services.list.length > 0) {
      //   this.setState({ type: this.props.services.list[0]['obj_services-proto_type'] }, () => this.changeSearchType(this.state.type))
      // }
    }
  }

  handleSave = () => {
    this.setState({ lockForm: true });
    const { intl, form } = this.props;
    const { itemMove } = this.state;
    let fields = ["obj_service-name", "obj_service-desc"]

    const protocol = itemMove['obj_services-proto_type'];
    switch (protocol) {
      case 'udp':
      case 'tcp':
        var list = document.getElementById(this.state.obj_service_list_id);
        if ((
              (itemMove['obj_services-port_src_end'] == '' || itemMove['obj_services-port_src_end'] == 0) &&
              (itemMove['obj_services-port_dst_end'] == '' || itemMove['obj_services-port_dst_end'] == 0)
            ) && list.options.length == 0) {
          fields.push('obj_services-port_src_end', 'obj_services-port_dst_end');
        }
        break;
      default:
        break;
    }

    form.validateFields(fields, (err, values) => {
      var list = document.getElementById(this.state.obj_service_list_id);
      if (list.options.length == 0) {
        this.setState({ lockForm: false });
        message.error(intl.formatMessage({ id: "objects.services.modal.form.invalid" }));
        list.classList.add("ui-has-error");
        return
      } else {
        if (!err) {
          this.saveObj(form, values);
        } else {
          this.setState({ lockForm: false });
        }
      }
    });
  }

  handleCancel = () => {
    const { onCancel, form } = this.props;
    // Setando o Protocol Type default para TCP
    this.setState({ type: protoID8 })
    form.resetFields();
    let field = document.getElementById("obj_time-list");
    if (field) field.classList.remove("ui-has-error");
    onCancel();
  }

  is_port = (num) => {
    if (num == null || num == '')
      return false;

    if (num < 0 || num > 65535)
      return false;

    return true;
  }

  renderListServices = (row) => {

    let proto_name = row['obj_services-proto_type'];
    let _proto_id = row["obj_services-proto_id"];
    let proto_value = row["obj_services-proto_value"];
    let port_src_ini = row["obj_services-port_src_ini"];
    let port_src_end = row["obj_services-port_src_end"];
    let port_dst_ini = row["obj_services-port_dst_ini"];
    let port_dst_end = row["obj_services-port_dst_end"];
    var obj_id = proto_id[proto_name];

    let value = '';
    let txt = '';

    if (proto_name === 'udp' || proto_name === 'tcp') {

      txt = proto_name;
      value = proto_name;

      if (this.is_port(port_src_end) && this.is_port(port_dst_end)) {
        txt = proto_name + '/' + port_src_end + ',' + port_dst_end + ' Source, Destination' + '';
        value = proto_name + '^srcdst^' + obj_id + '^' + port_src_end + '^' + port_dst_end;
      }
      //destino (tcp/udp)
      else if (!this.is_port(port_src_end) && !this.is_port(port_dst_ini) && this.is_port(port_dst_end)) {
        txt = proto_name + '/' + port_dst_end + ' Destination' + '';
        value = proto_name + '^dst^' + obj_id + '^' + port_dst_end;
      }
      //origem (tcp/udp)
      else if (!this.is_port(port_dst_end) && !this.is_port(port_dst_ini) && this.is_port(port_src_end)) {
        txt = proto_name + '/' + port_src_end + ' Source' + '';
        value = proto_name + '^src^' + obj_id + '^' + port_src_end;
      }
      //origem (tcp/udp)
      else if (!this.is_port(port_dst_end) && this.is_port(port_dst_ini) && !this.is_port(port_src_end)) {
        txt = proto_name + '/' + port_dst_ini + ' Source' + '';
        value = proto_name + '^src^' + obj_id + '^' + port_dst_ini;
      }
      //origem (tcp/udp)
      else if (this.is_port(port_dst_ini) && this.is_port(port_dst_end)) {
        txt = proto_name + '/' + port_dst_ini + ':' + port_dst_end + ' Range' + '';
        value = proto_name + '^range^' + proto_value + '^' + port_dst_ini + '^' + port_dst_end;
      }
    } else if (proto_name === 'ip') {
      var hash = [];
      hash['1'] = 'icmp';
      hash['2'] = 'tcp';
      hash['3'] = 'udp';
      hash['5'] = 'gre';
      hash['6'] = 'esp';
      hash['7'] = 'ah';
      hash['8'] = 'igmp';
      hash['9'] = 'egp';
      hash['10'] = 'sctp';
      hash['11'] = 'rsvp';
      hash['12'] = 'ospf';
      hash['14'] = 'ipv4';
      hash['15'] = 'ipv6';
      hash['16'] = 'ipcomp';
      hash['17'] = 'ipip';
      hash['18'] = 'igp';

      txt = proto_name + '/' + hash[proto_value];
      value = proto_name + '^' + proto_name + '^' + proto_value + '^' + protoID[proto_value];

    } else if (proto_name === 'icmp') {
      var hash = [];
      hash['8'] = 'Echo Request';
      hash['0'] = 'Echo Reply';
      hash['3'] = 'Destination Unreachable';
      hash['3/0'] = 'Net Unreachable';
      hash['3/1'] = 'Host Unreachable';
      hash['3/7'] = 'Destination Host Unknown';
      hash['3/6'] = 'Destination Network Unknown';
      hash['5'] = 'Redirect';
      hash['11'] = 'Time Exceeded';

      txt = 'icmp/' + hash[proto_value];
      value = 'icmp^icmp^' + proto_value + "^" + _proto_id;

    } else if (proto_name === 'icmpv6') {

      var hash = [];
      hash['1'] = 'Destination Unreachable';
      hash['2'] = 'Packet Too Big';
      hash['3'] = 'Time Exceeded';
      hash['4'] = 'Parameter Problem';
      hash['128'] = 'Echo Request';
      hash['129'] = 'Echo Reply';
      hash['130'] = 'Multicast Listener Query';
      hash['131'] = 'Multicast Listener Report';
      hash['132'] = 'Multicast Listener Done';
      hash['133'] = 'Router Solicitation';
      hash['134'] = 'Router Advertisement';
      hash['135'] = 'Neighbor Solicitation';
      hash['136'] = 'Neighbor Advertisement';
      hash['137'] = 'Redirect Message';
      hash['138'] = 'Router Renumbering';
      hash['139'] = 'ICMP Node Information Query';
      hash['140'] = 'ICMP Node Information Response';
      hash['141'] = 'Inverse ND Solicitation Message';
      hash['142'] = 'Inverse ND Advertisement Message';
      hash['143'] = 'Version2 Multicast Listener Report';
      hash['144'] = 'Ha Address Discovery Request Message';
      hash['145'] = 'Ha Address Discovery Reply Message';
      hash['146'] = 'Mobile Prefix Solicitation';
      hash['147'] = 'Mobile Prefix Advertisement';
      hash['148'] = 'Certification Path Solicitation Message';
      hash['149'] = 'Certification Path Advertisement';
      hash['151'] = 'Multicast Router Advertisement';
      hash['152'] = 'Multicast Router Solicitation';
      hash['153'] = 'Multicast Router Termination';
      hash['154'] = 'FMIPv6';
      hash['157'] = 'Duplicate Address Request';
      hash['158'] = 'Duplicate Address Confirmation';

      txt = 'icmpv6/' + hash[proto_value];
      value = 'icmpv6^icmpv6^' + proto_value + "^" + _proto_id;

    }


    return (<option key={txt.toString()} value={value}>{txt}</option>)
  }

  changeSearchType = (value) => {
    const { itemMove } = this.state;
    const { getFieldDecorator, setFieldsValue } = this.props.form;

    let object = {}
    object['obj_services-proto_type'] = value
    getFieldDecorator('obj_service-protocol');

    switch (value) {
      case "udp":
      case "tcp":
        object['obj_services-proto_id'] = object['obj_services-proto_value'] = proto_id[value]
        break;
      case "ip":
        object['obj_services-proto_id'] = object['obj_services-proto_value'] = protoIP[0].id
        setFieldsValue({ "obj_service-protocol": protoIP[0].id });
        break;
      case "icmp":
        object['obj_services-proto_id'] = proto_id[value]
        object['obj_services-proto_value'] = icmpV4[0].id
        setFieldsValue({ "obj_service-protocol": icmpV4[0].id });
        break;
      case "icmpv6":
        object['obj_services-proto_id'] = object['obj_services-proto_value'] = icmpV6[0].id
        setFieldsValue({ "obj_service-protocol": icmpV6[0].id });
        break;
    }
    this.setState({ itemMove: { ...itemMove, ...object }, type: value });
  }

  setProtocol = (value) => {
    const { itemMove } = this.state;
    this.setState({
      itemMove: {
        ...itemMove,
        //'obj_services-proto_id': value,
        'obj_services-proto_value': value
      }
    });
  }

  setPortType = (value) => {
    const { itemMove } = this.state;
    this.setState({ itemMove: { ...itemMove, 'obj_services-port_type': value } });
  }

  setIniPort = (value) => {

    const { itemMove } = this.state;

    if (itemMove['obj_services-port_type'] == 'range')
      this.setState({
        itemMove: {
          ...itemMove,
          'obj_services-port_dst_ini': `${value}`,
        }
      })

    if (itemMove['obj_services-port_type'] == 'srcdst')
      this.setState({ itemMove: { ...itemMove, 'obj_services-port_src_end': `${value}` } })

  }

  setEndPort = (value) => {
    const { itemMove } = this.state;

    if (['range', 'srcdst'].includes(itemMove['obj_services-port_type'])) {
      this.setState({ itemMove: { ...itemMove, 'obj_services-port_dst_end': `${value}` } });
    }
  }

  handlePortInputKeyDown = (e) => {
    if (!["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "Backspace", "ArrowLeft", "ArrowRight", "Tab"].includes(e.key)) {
      e.preventDefault();
    }
  };

  handlePortChange = (value, setterFunction) => {
    if (value !== undefined) {
      const intValue = Math.floor(value);
      setterFunction(intValue);
    }
  };

  moveValueList = (value) => {
    const { itemMove } = this.state;
    const { getFieldValue, validateFields, resetFields } = this.props.form;

    const portFields = {
      'srcdst': ['obj_services-port_src_end', 'obj_services-port_dst_end'],
      'range': ['obj_services-port_dst_ini', 'obj_services-port_range_end'],
    };

    const type = getFieldValue('obj_service-protocol-port-type');
    const protocol = getFieldValue('obj_service-protocol-type');

    let fields = [];

    switch (protocol) {
      case 'icmp':
      case 'ip':
      case 'icmpv6':
        fields = ["obj_service-protocol"];
        break;
      case 'udp':
      case 'tcp':

        if (Object.keys(portFields).includes(type)) {
          fields = portFields[type];
        } else {
          fields = this.setField(getFieldValue('obj_service-protocol-port-type'));
        }
        break;
      default:
        break;
    }

    let findErrors = false;

    validateFields(fields, (errors) => {
      if (errors) {
        findErrors = true;
      }
    });

    if (findErrors) {
      return;
    }

    if (type === 'range') {
      if (value['obj_services-port_dst_end'] == '') {
        return message.error(this.props.intl.formatMessage({ id: "common.error.required" }, { name: this.props.intl.formatMessage({ id: "objects.services.modal.form.destination.port" }) }));
      }
      if (value['obj_services-port_dst_ini'] == '') {
        return message.error(this.props.intl.formatMessage({ id: "common.error.required" }, { name: this.props.intl.formatMessage({ id: "objects.services.modal.form.source.port" }) }));
      }

      if (value['obj_services-port_dst_ini'] == value['obj_services-port_range_end']) {
        return message.error(this.props.intl.formatMessage({ id: "devices.service.dhcp.message.invalid.range" }));
      }

      if ((parseInt(value['obj_services-port_dst_ini']) > parseInt(value['obj_services-port_range_end'])) || 
        (parseInt(value['obj_services-port_dst_ini']) > parseInt(value['obj_services-port_dst_end']))) {
        return message.error(this.props.intl.formatMessage({ id: "devices.service.dhcp.message.invalid.range" }));
      }
    }
    resetFields([
      "obj_services-port_range_end",
      "obj_services-port_dst_end",
      "obj_services-port_dst_ini",
      "obj_services-port_src_end",
      "obj_services-port_src_ini",
      //"obj_service-protocol"
    ]);

    this.setState({
      itemMove: {
        ...itemMove,
        "obj_services-port_dst_end": "",
        "obj_services-port_dst_ini": "",
        "obj_services-port_src_end": "",
        "obj_services-port_src_ini": "",
        "obj_services-proto_type": protoID8,
        //"obj_service-protocol": ""
      }
    });

    this.addValueList(value);
  }

  removeValueList = () => {
    let selectElement = document.getElementById(this.state.obj_service_list_id);
    let values = Array.from(selectElement.selectedOptions).map(option => option.index)
    this.delValueList(values);
  }

  addValueList = (value) => {
    let { list } = this.state.services;
    let object = list.some(p =>
      p["obj_services-port_dst_end"] == value["obj_services-port_dst_end"] &&
      p["obj_services-port_dst_ini"] == value["obj_services-port_dst_ini"] &&
      p["obj_services-port_src_end"] == value["obj_services-port_src_end"] &&
      p["obj_services-port_src_ini"] == value["obj_services-port_src_ini"] &&
      p["obj_services-proto_id"] == value["obj_services-proto_id"] &&
      //	p["obj_services-proto_key"] == value["obj_services-proto_key"] &&
      p["obj_services-proto_type"] == value["obj_services-proto_type"] &&
      p["obj_services-proto_value"] == value["obj_services-proto_value"]
    )

    if (object == false) {
      list.push(value);
    }
    this.setState({
      services: {
        ...this.state.services,
        list
      }
    });

    var element = document.getElementById(this.state.obj_service_list_id);
    element.classList.remove("ui-has-error")

  }

  delValueList = (values) => {
    let { list } = this.state.services;
    values.forEach(function (index) {
      delete list[index];
    })
    list = list.filter(p => p);
    this.setState({
      services: {
        ...this.state.services,
        list
      }
    });
  }

  saveObj = (form, values) => {
    const { intl } = this.props;
    const { services } = this.state;
    const itens = [];

    var element = document.getElementById(this.state.obj_service_list_id);
    for (var i = 0; i < element.options.length; i++) {
      itens.push(element.options[i].value);
    }

    const bodyData = new FormData();
    bodyData.set("act", "saveService");
    bodyData.set("obj_service-name", values["obj_service-name"]);
    bodyData.set("obj_service-desc", values["obj_service-desc"] || '');
    bodyData.set("obj_service-list[]", itens);

    if (services.id !== '') {
      bodyData.set("obj_service-id", services.id);
    }

    Axios.post(ajaxUrl[package_version].objects, bodyData).then((res) => {
      this.setState({ lockForm: false });

      if (product == "GSM" && Array.isArray(res.data) && res.data.length == 2)
        if (res.data[1]['errorcode'] == '001') {
          form.resetFields();
          this.props.onCancel()
          element.classList.remove("ui-has-error");
          this.setState({ type: protoID8, services: { list: [], id: "" } });
          return message.error(this.props.intl.formatMessage({ id: "common.dont.permission" }))
        }

      if (res.data === "Seu login não permite acesso a esta area." || res.data === "Your login does not allow access to this area.") {
        form.resetFields();
        this.props.onCancel()
        element.classList.remove("ui-has-error");
        this.setState({ type: protoID8, services: { list: [], id: "" } });
        return message.error(this.props.intl.formatMessage({ id: "common.dont.permission.edit" }));
      } else if (res.data === "High Availabilty Habilitado - Estado: Backup - Acesso Bloqueado." || res.data === "High Availabilty Enabled - State: Backup - Blocked Access.") {
        form.resetFields();
        this.props.onCancel()
        element.classList.remove("ui-has-error");
        this.setState({ type: protoID8, services: { list: [], id: "" } });
        return message.error(this.props.intl.formatMessage({ id: "common.blocked.ha" }));
      }

      if (res.data[0].success == 1) {
        message.success(intl.formatMessage({ id: "objects.services.modal.form.save.success" }));
        form.resetFields();
        this.setState({ type: protoID8, services: { list: [], id: "" } });
        element.classList.remove("ui-has-error");
        this.props.onOk();
      } else {
        res.data.forEach(erro => {
          form.setFields({
            [erro.field]: {
              value: values[erro.field],
              errors: [new Error(intl.formatMessage({ id: "common.invalid.value" }))],
            },
          });
          if (erro.field == "obj_service-list[]") {
            element.classList.add("ui-has-error")
          }

          if (res.data[0]['exists']) {
            form.setFields({
              'obj_service-name': {
                value: form.getFieldValue('obj_service-name'),
                errors: [new Error(this.props.intl.formatMessage({ id: "common.alreadyExists" }))]
              }
            })
          }
          message.error(intl.formatMessage({ id: "objects.actions.create.error" }));
        });

      }
    });
  }

  render() {
    let { intl, visible, titlePrefix } = this.props;
    let { services, lockForm, editTitlePrefix } = this.state;

    const { getFieldDecorator, getFieldValue } = this.props.form;
    const { TextArea } = Input;
    const Option = Select.Option;
    const formLayout = { colon: false, style: { paddingBottom: 0 } };
    const formLayoutLast = { colon: false, style: { paddingBottom: 0, marginBottom: 0 } };

    if (titlePrefix == '' && editTitlePrefix == '') {
      titlePrefix = intl.formatMessage({ id: "common.create" });
    }
    let { list: rows } = this.state.services;

    let controleLoadingButton = lockForm
    let lockFormGsmUtm = false

    //Object GSM disabled
    if (this.props.services.gsmid || this.state.services.gsmid) {
      lockFormGsmUtm = true;
      controleLoadingButton = true;
    }

    const footerActions = [
      <Button key="cancel" onClick={this.handleCancel}>
        <FormattedMessage id="common.cancel" />
      </Button>,
      <Button loading={controleLoadingButton} disabled={lockFormGsmUtm} key="save" type="primary" onClick={this.handleSave}>
        <FormattedMessage id="common.save" />
      </Button>
    ]

    const validatePortField = (intl) => (rule, value, callback) => {
      if (hasEmojis(value)) {
        return callback(intl.formatMessage({ id: "common.invalid.value" }));
      }
      if (value && value.match(/[^0-9]+/)) {
        return callback(intl.formatMessage({ id: "common.error.invalid.value" }));
      }
      callback();
    };

    return <DraggableModal
      onOk={this.handleSave}
      onCancel={this.handleCancel}
      confirmLoading={lockFormGsmUtm}
      visible={visible}
      title={<Fragment>{titlePrefix || editTitlePrefix}<FormattedMessage id="objects.modal.title" values={{ object: "Services" }} /></Fragment>}
      okText={intl.formatMessage({ id: "common.save" })}
      cancelText={intl.formatMessage({ id: "common.cancel" })}
      footer={footerActions}>
      <Form layout="vertical" id="form-object-services">
        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.services.modal.form.name" })} >
          {getFieldDecorator('obj_service-name', {
            initialValue: services.name,
            rules: [
              { required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.services.modal.form.name" }) }) },
              {
                validator: (rule, value, cb) => {
                  if (hasEmojis(value)) {
                    cb(intl.formatMessage({ id: "common.invalid.value" }));
                  } else {
                    cb();
                  }
                }
              }
            ]
          })(
            <Input disabled={lockFormGsmUtm} />
          )}
        </Form.Item>

        <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.services.modal.form.type" })}>
          {getFieldDecorator('obj_service-protocol-type', {
            initialValue: this.state.type,
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.services.modal.form.type" }) }) }]
          })(
            <Select disabled={lockFormGsmUtm} onChange={(value) => this.changeSearchType(value)}
              placeholder="Select a type">
              <Option value=""></Option>
              <Option value="tcp">{intl.formatMessage({ id: "objects.services.modal.form.tcp" })}</Option>
              <Option value="udp">{intl.formatMessage({ id: "objects.services.modal.form.udp" })}</Option>
              <Option value="ip">{intl.formatMessage({ id: "objects.services.modal.form.ip" })}</Option>
              <Option value="icmp">{intl.formatMessage({ id: "objects.services.modal.form.icmp" })}</Option>
              <Option value="icmpv6">{intl.formatMessage({ id: "objects.services.modal.form.icmpv6" })}</Option>
            </Select>
          )}
        </Form.Item>

        {(this.state.type === 'ip' || this.state.type === 'icmp' || this.state.type === 'icmpv6') && <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.services.modal.form.protocol" })} style={{ marginBottom: 8, paddingBottom: 0 }}>
          <div style={{ display: 'flex' }}>
            {getFieldDecorator('obj_service-protocol', { //'obj_service-protocol-icmp'
              initialValue: "",
              rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.services.modal.form.protocol" }) }) }]
            })(
              <Select style={{ marginRight: '8px' }}
                disabled={lockFormGsmUtm}
                onChange={this.setProtocol}
                placeholder="Select a type">
                {(this.state.type === 'ip') && Object.keys(protoIP).map((item, index) => <Select.Option key={index} value={protoIP[index].id}>{protoIP[index].name}</Select.Option>)}
                {(this.state.type === 'icmp') && Object.keys(icmpV4).map((item, index) => <Select.Option key={index} value={icmpV4[index].id}>{icmpV4[index].name}</Select.Option>)}
                {(this.state.type === 'icmpv6') && Object.keys(icmpV6).map((item, index) => <Select.Option key={index} value={icmpV6[index].id}>{icmpV6[index].name}</Select.Option>)}

              </Select>
            )}
            <button onClick={() => this.moveValueList(this.state.itemMove)} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>
          </div>
        </Form.Item>}

        {((getFieldValue('obj_service-protocol-type') === 'tcp' || getFieldValue('obj_service-protocol-type') === 'udp')) && <Form.Item {...formLayout} label={intl.formatMessage({ id: "objects.services.modal.form.portType" })} style={{ marginBottom: 8, paddingBottom: 0 }}>

          {getFieldDecorator('obj_service-protocol-port-type', {
            initialValue: "srcdst",
            rules: [{ required: true, message: intl.formatMessage({ id: "common.error.required" }, { name: intl.formatMessage({ id: "objects.services.modal.form.port" }) }) }]
          })(
            <Select
              disabled={lockFormGsmUtm}
              style={{ marginRight: '8px' }}
              onChange={this.setPortType}
              placeholder="Select a type">
              {portType.map((item, index) => <Option key={index} value={item.id}>{item.type}</Option>)}
            </Select>
          )}

        </Form.Item>}

        {((this.state.type === 'tcp' || this.state.type === 'udp') && ((getFieldValue('obj_service-protocol-port-type') == "srcdst") || ((getFieldValue('obj_service-protocol-port-type') == 'range')))) && <Form.Item {...formLayout} required label={intl.formatMessage({ id: "objects.services.modal.form.port" })} style={{ marginBottom: 8, paddingBottom: 0 }}>
          <div style={{ display: 'flex' }}>
            {(getFieldValue('obj_service-protocol-port-type') == 'srcdst' &&
              <Fragment>
                <Form.Item {...formLayout}>
                  {getFieldDecorator('obj_services-port_src_end', {
                    rules: [
                      {
                        required: (this.state.itemMove['obj_services-port_dst_end'] == '' || this.state.itemMove['obj_services-port_dst_end'] == 0),
                        message: intl.formatMessage({
                          id: "common.error.required"
                        }, {
                          name: intl.formatMessage({
                            id: "objects.services.modal.form.port"
                          })
                        })
                      },
                      {
                        validator: validatePortField(intl)
                      }
                    ]
                  })(
                    <InputNumber
                      disabled={lockFormGsmUtm}
                      onChange={(value) => this.handlePortChange(value, this.setIniPort)}
                      onKeyDown={this.handlePortInputKeyDown}
                      min={1}
                      max={65535}
                      placeholder={intl.formatMessage({ id: "objects.services.modal.form.source.port" })} />
                  )}
                </Form.Item>
                <Form.Item {...formLayout}>
                  {getFieldDecorator('obj_services-port_dst_end', {
                    rules: [
                      {
                        required: (this.state.itemMove['obj_services-port_src_end'] == '' || this.state.itemMove['obj_services-port_src_end'] == 0),
                        message: intl.formatMessage({
                          id: "common.error.required"
                        }, {
                          name: intl.formatMessage({
                            id: "objects.services.modal.form.port"
                          })
                        })
                      },
                      {
                        validator: validatePortField(intl)
                      }
                    ]
                  })(
                    <InputNumber
                      disabled={lockFormGsmUtm}
                      onChange={(value) => this.handlePortChange(value, this.setEndPort)}
                      onKeyDown={this.handlePortInputKeyDown}
                      min={1}
                      max={65535}
                      placeholder={intl.formatMessage({ id: "objects.services.modal.form.destination.port" })} />
                  )}
                </Form.Item>
              </Fragment>
            )}

            {(getFieldValue('obj_service-protocol-port-type') == 'range' &&
              <Fragment>
                <Form.Item {...formLayout}>
                  {getFieldDecorator('obj_services-port_dst_ini', {
                    initialValue: "",
                    rules: [
                      {
                        required: (getFieldValue('obj_service-protocol-port-type') === 'range'),
                        message: intl.formatMessage({
                          id: "common.error.required"
                        }, {
                          name: intl.formatMessage({
                            id: "objects.services.modal.form.port"
                          })
                        })
                      },
                      {
                        validator: validatePortField(intl)
                      }
                    ]
                  })(
                    <InputNumber
                      disabled={lockFormGsmUtm}
                      onChange={(value) => this.handlePortChange(value, this.setIniPort)}
                      onKeyDown={this.handlePortInputKeyDown}
                      min={1}
                      max={65535}
                      placeholder={intl.formatMessage({ id: "objects.services.modal.form.source.port" })} />
                  )}
                </Form.Item>
                <Form.Item {...formLayout}>
                  {getFieldDecorator('obj_services-port_range_end', {
                    initialValue: "",
                    rules: [
                      {
                        required: (getFieldValue('obj_service-protocol-port-type') === 'range'),
                        message: intl.formatMessage({
                          id: "common.error.required"
                        }, {
                          name: intl.formatMessage({
                            id: "objects.services.modal.form.port"
                          })
                        })
                      },
                      {
                        validator: validatePortField(intl)
                      }
                    ]
                  })(
                    <InputNumber
                      disabled={lockFormGsmUtm}
                      onChange={(value) => this.handlePortChange(value, this.setEndPort)}
                      onKeyDown={this.handlePortInputKeyDown}
                      min={1}
                      max={65535}
                      placeholder={intl.formatMessage({ id: "objects.services.modal.form.destination.port" })} />
                  )}
                </Form.Item>
              </Fragment>
            )}
            <button style={{ height: 32 }} disabled={lockFormGsmUtm} onClick={() => this.moveValueList(this.state.itemMove)} className="btn btn-sm" title={intl.formatMessage({ id: "common.add.to.list" })}><i className="fa fa-plus" /></button>
          </div>
        </Form.Item>}

        
        <Form.Item {...formLayout} label=''>
          <div style={{ display: 'flex' }}>
            <select disabled={lockFormGsmUtm} id={this.state.obj_service_list_id} style={{ marginRight: '8px' }} className="ant-input" multiple={true}>
              {rows.map(this.renderListServices)}
            </select>

            <button disabled={lockFormGsmUtm} onClick={() => this.removeValueList()} style={{ height: 32, marginTop: '20px' }} className="btn btn-sm" title={intl.formatMessage({ id: "common.del.to.list" })}><i className="fa fa-minus" /></button>
          </div>
        </Form.Item>

        <Form.Item {...formLayoutLast} label={intl.formatMessage({ id: "objects.services.modal.form.description" })} >
          {getFieldDecorator('obj_service-desc', {
            initialValue: services.description,
            rules: [
              {
                validator: (rule, value, cb) => {
                  if (hasEmojis(value)) {
                    cb(intl.formatMessage({ id: "common.invalid.value" }));
                  } else {
                    cb();
                  }
                }
              }
            ]
          })(
            <TextArea disabled={lockFormGsmUtm} />
          )}
        </Form.Item>
      </Form >
    </DraggableModal>
  }
}

ServicesModal.defaultProps = {
  onCancel: null,
  visible: false,
  titlePrefix: '',
  id: null,
  services: {
    id: '',
    name: '',
    description: '',
    list: [],
  }
}

export default injectIntl(Form.create({})(ServicesModal));
import React, { Component, PureComponent  } from "react";
import { WorldMap2, Chart, Loading } from "/bbui/components";
import { Card, Table, Col, Row, Icon, Input } from "antd";
import { injectIntl } from "react-intl";
const { Search } = Input;

class ReportWidget extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      intl,
      title,
      loading,
      options,
      series,
      chartType,
      chartHeight,
      columns,
      dataSource,
      handleChangeHits,
      handleChangeTraffic,
      totalHits,
      totalTraf,
      chart,
      worldMap,
      footer,
      grid,
      nameWidget,
      typeActive,
      allLoads,
      height,
      heightGrid,
      chart2,
      options2,
      series2,
      chartType2,
      chartHeight2,
      filter,
      onSearch
    } = this.props;

    return (
      <React.Fragment>
        <Card
          extra={
            filter && <Search enterButton onSearch={onSearch} size="small" />
          }
          size="small"
          title={title}
          style={{ marginBottom: 12 }}
          headStyle={{ minHeight: 30 }}
        >
          <Loading loading={loading} />
          {worldMap && <WorldMap2 allowedDateType={this.props.allowedDateType} drillDown={this.props.drillDown} drillDown2={this.props.drillDown2} typeDate={this.props.typeDate} date={this.props.date} height={height} dataSource={dataSource} />}
          {chart && (
            <Chart
              options={options}
              series={series}
              type={chartType}
              height={chartHeight}
            />
          )}
          {chart2 && (
            <Chart
              options={options2}
              series={series2}
              type={chartType2}
              height={chartHeight2}
            />
          )}
          {grid && (
            <Table
              className="widget-table"
              size="small"
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              style={{ height: heightGrid }}
            />
          )}
          {footer && (
            <div>
              <hr></hr>
              <Row gutter={16}>
                <Col span={12} style={{ cursor: "pointer" }}>
                  <div
                    style={{
                      fontWeight: typeActive == "Traffic" ? "bold" : "normal"
                    }}
                    onClick={() => {
                      // impossibilita a troca de filtros enquanto um widget carrega,
                      if (allLoads != null) {
                        Object.values(allLoads).includes(true)
                          ? ""
                          : handleChangeTraffic(nameWidget);
                      }
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Icon type="wifi" />
                      <span style={{ marginLeft: "5px" }}>
                        {" "}
                        {intl.formatMessage({
                          id: "analyzer.reports.component.footer.totalTraffic"
                        })}
                      </span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {totalTraf}
                    </div>
                  </div>
                </Col>
                <Col span={12} style={{ cursor: "pointer" }}>
                  <div
                    style={{
                      fontWeight: typeActive == "Hits" ? "bold" : "normal"
                    }}
                    onClick={() => {
                      // impossibilita a troca de filtros enquanto um widget carrega,
                      if (allLoads != null) {
                        Object.values(allLoads).includes(true)
                          ? ""
                          : handleChangeHits(nameWidget);
                      }
                    }}
                  >
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <Icon type="bar-chart" />
                      <span style={{ marginLeft: "5px" }}>
                        {intl.formatMessage({
                          id: "analyzer.reports.component.footer.totalHits"
                        })}
                      </span>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      {totalHits}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          )}
        </Card>
      </React.Fragment>
    );
  }
}

ReportWidget.defaultProps = {
  filter: false,
  allLoads: { value: false },
  title: null,
  loading: false,
  worldMap: false,
  chart: false,
  grid: false,
  chart2: false,
  series: [],
  options: {},
  chartType: "area",
  chartType2: "bar",
  chartHeight: 220,
  chartHeight2: 220,
  heightGrid: "395px",
  columns: [],
  dataSource: []
};

export default injectIntl(ReportWidget);
